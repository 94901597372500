//image imports
import darvily_icon from './img/darvily.webp';
import meme_icon from './img/meme.webp';

// darvily media
import darvily_advice from './media/sounds/advice.mp3';
import darvily_scream from './media/sounds/scream.mp3';

// darvily clips

// meme media 
import toothless_dance from './media/meme/toothless_dance.mp3';

const data = [
        {
            id: 1,
            name: 'Darvily',
            image: darvily_icon,
            sounds: [darvily_advice, darvily_scream],
            soundNames: ['Advice (9s)', 'Scream [LOUD] (10s)']
        },
        {
            id: 2,
            name: 'Random',
            image: meme_icon,
            sounds: [toothless_dance],
            soundNames: ['Toothless Dance']
        }
]

export default data;